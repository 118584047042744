.faqs .vectorWrapper {
    --linear-yellow: #FFA811;
    --linear-pink: rgb(248, 108, 167);

    display: flex;
    align-items: center;
    justify-content: center;
    width: 33px;
    height: 30px;
    color: #fff !important;
    background: linear-gradient(90deg,var(--linear-pink) 0%,var(--linear-yellow) 100%);
    border-radius: 50rem;
}

.faq {
    user-select: none;
}

.faq .question {
    padding: 0.8rem 1.6rem;
    cursor: pointer;
}

.answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 1.4s ease;
}

.faq.activeFaq .answer {
    max-height: 900px;
    animation: fade 1s ease-in-out;
}

.caret {
    transition: 0.5s ease-in;
}

@keyframes fade {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 100%;
        transform: translateY(0px);
    }
}

.hovereffect :hover{
    -ms-transform: scale(1.05); /* IE 9 */
    -webkit-transform: scale(1.05); /* Safari 3-8 */
    transform: scale(1.05); 
}
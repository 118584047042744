/* Newslettr =========================== */
.subsctibeBar .content {
    width: 360px;
    height: 40px;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, .2);
    border-radius: 0;
    overflow: hidden;
}

.subscription {
    position: relative;
    width: 100%;
    height: 100%;
}

.subscription .add-email {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding: 0 20px;
    color: #000;
}

.subscription .submit-email {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100px;
    border: none;
    border-radius: 0;
    outline: none;
    padding: 0 20px;
    cursor: pointer;
    background: linear-gradient(90deg, var(--red), var(--secondColor));
    transition: width .35s ease-in-out,
        background .35s ease-in-out;
    color: #fff;
}

.subscription.done .submit-email {
    width: 100%;
}

.subscription .submit-email .before-submit,
.subscription .submit-email .after-submit {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 38px;
    transition: visibility .35s ease-in-out,
        opacity .35s ease-in-out;
}

.subscription.done .submit-email .before-submit,
.subscription:not(.done) .submit-email .after-submit {
    visibility: hidden;
    opacity: 0;
}

.subscription .submit-email .after-submit {
    transition-delay: .35s;
}

.subscription:not(.done) .submit-email .before-submit,
.subscription.done .submit-email .after-submit {
    visibility: visible;
    opacity: 1;
}
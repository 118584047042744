.quoteAuther {
    font-size: 1.5rem;
}
.quoteContent {
    background-color: var(--lightColor);
}
.quoteContent .quoteIcon {
    width: 30px;
    height: 30px;
    top: -10%;
    left: 5%;
}
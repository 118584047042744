.portfolio .poster {
    height: 480px;
    position: relative;
    overflow: hidden;
}
.portfolio .poster .img {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
}
.portfolio .poster .content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 70%;
}
.img::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #000000b8;
}
.portfolio .poster:nth-child(odd) {
    bottom: 40px;
}
.portfolio .content * {
    text-shadow: var(--textShadow);
}
.portfolio .poster:hover .content {
    top: 0;
}
.poster:hover .img::after{
    transition: 500ms;
    background-image: linear-gradient(to top, var(--red), var(--secondColor));
}
.poster:hover .img{
    transform: scale(1.2);
}
.poster:hover {
    z-index: 1;
    transition: 200ms;
    transform: scaleX(1.1);
}
@media (max-width: 720px) {
    .portfolio .poster:nth-child(odd) {
        bottom: 0;
    }
}
.service:nth-child(even) {
    flex-direction: row-reverse;  
}
.servicesShort .imgWrapper{
    width: 35%;
}
@media (max-width: 762px) {
    .servicesShort .imgWrapper{
        width: 10%;
    }
}
@media (max-width: 576px) {
    .servicesShort .imgWrapper{
        width: 25%;
    }
}
@media (max-width: 480px) {
    .servicesShort .imgWrapper{
        width: 30%;
    }
}
.navbar {
    transition: 1s !important;
    z-index: 99 !important;
    --bs-navbar-padding-y: 0rem !important; /* Sept 13, 23 */
    --dropdown-anim-duration: 500ms;
}

.coloredNav {
    background-color: #fff;
    
}

.scrollNav {
    background-image: linear-gradient(to top, #ffffff91, #fff);
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(3px);
}

.navbar .navCross {
    transition: all var(--dropdown-anim-duration) ease;
    color: var(--red);
}

.navbar li>.nav-item {
    font-weight: 600;
    font-size: 1.2rem;
}

.navbar .collapse .dropdown-item:hover {
    /* for gradient bg  */
    /* transition: 300ms; */
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    background-image: linear-gradient(90deg, var(--red), var(--secondColor));

    /* for gradient text */
    /* background: linear-gradient(90deg, var(--red), #ffa200);
    background-clip: text;
    -webkit-text-fill-color: transparent; */
}

/* Sept 13, 2023 ================= */
.navbar li {
    list-style-type: none;
}

.navbar .nav-item .dropdownMenu {
    transition: max-height 300ms ease-in, transform 500ms ease-in-out !important;
    transform: translateY(50%);
    max-height: 0px;
    position: absolute;
    padding: 0;
}

.navbar .nav-item:hover .dropdownMenu {
    transform: translateY(0%);
    max-height: 300px;
}

.navbar .collapse .nav-link {
    padding: 1.8rem 0;
}

/* Sept 13, 2023 ================= */

/* left responsive navbar  */
.navbar .navCollapse {
    top: 0;
    left: 0;
    height: 100vh !important;
    z-index: 100;
    background-color: #fff;
    width: 300px;
    height: 60px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    /* z-index: 999 !important; */
}
.navCollapse .nav-item > .dropdownMenuCollpase {
    /* background-color: #ffa200; */
    border: none !important;
    max-height: 0px;
    transition: max-height 1300ms linear !important;
}
.navbar .collapse .nav-item:hover .navIcon, .navCross {
    transform: rotate(180deg);
}

/* Sept 13, 2023 ================= */
.navbar .dropdownMenuCollpase .dropdown-item:hover {
    transition: all 400ms;
    transform: translateX(10px);
    background: linear-gradient(90deg, var(--red), #ffa200);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.nav-link:hover {
    background: linear-gradient(90deg, var(--red), #ffa200);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
/* Sept 13, 2023 ================= */

/* for animation */
.navbar .visible {
    transform: translateX(0%) !important;
}

.navbar .notVisible {
    transform: translateX(-100%) !important;
}

/* Sept 13, 2023 ================= */
.activeDropdown {
    max-height: 160px !important;
}
.rotateCross {
    transform: rotate(180deg);
}
/* Sept 13, 2023 ================= */
.process .step {
    position: relative;
    border-radius: 10px;
    background: #e8e8e8 !important;
    border-color: none !important;
    box-shadow: 5px 5px 5px 5px #e8e8e8
}
.process .label {
    width: 50px;
    height: 30px;
    position: absolute;
    top: 1px;
    left: 1px;
    background-color: #fff;
    clip-path: polygon(100% 0%, 80% 50%, 100% 100%, 0% 100%, 0% 0%);
}
.step:hover .label {
    border-radius: 10px;
    background-image: var(--gradient);
}
.step .label {
    border-radius: 10px;
    color: #fff;
    background-image: var(--gradient);
}
.process .step:hover {
    /* border: 1px solid #ffa200 !important; */
    border-radius: 10px;
    box-shadow: 5px 5px 5px 5px #ffefd4;
    background: #ffefd4 !important;
    -ms-transform: scale(1.05); /* IE 9 */
    -webkit-transform: scale(1.05); /* Safari 3-8 */
    transform: scale(1.05); 

}
.processImage{
     height: 70px !important;
     color: #ffefd4;
     fill: green;
}

.contactAll .field {
    width: 100%;
    padding: 15px;
    background-color: var(--lightColor);
}
.contactAll input,textarea {
    padding: 0 15px;
    border: none;
    border-left: 1px solid var(--secondColor);
    width: 100%;
}
.contactAll button {
    border-radius: 2px;
}

.fs-5{
    font-size: 1rem !important;
}

.gap-2{
     gap : 0.8rem !important;
}
.justify-centent-center{
    justify-content: center;
}
.titlesection{
    width: 92%;
    padding-left: 58px;
}

.recentPosts .thumbnail{
    margin-top: 5px;
    height: 75px;
}
.blogpageRecent .thumbnail{
    margin-top: 5px;
    height: 75px;
}
/* Sept 14, 2023 ================= */
.somePosts .thumbnail{
    max-width: 75px;
    max-height: 75px;
    min-width: 75px;
    min-height: 75px;
    width: 75px;
    height: 75px;
}
/* Sept 14, 2023 ================= */
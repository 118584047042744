.companies {
    /* animation: slide 200ms linear infinite; */
     /* try 👆 */
    animation: slide 8s linear infinite;
}
/* 
.companies:nth-child(2) {
    animation-delay: 2s;
} */

@keyframes slide  {
    0% {
        transform: translateX(0%);
    } 
    100% {
        transform: translateX(-100%);
    }
}

.hovereffect :hover{
    -ms-transform: scale(1.05); /* IE 9 */
    -webkit-transform: scale(1.05); /* Safari 3-8 */
    transform: scale(1.05); 
}
.imgWrapper {
    overflow: hidden;
    background-position: center center;
    background-position: cover;
}
.imgWrapper img{
    object-fit: cover;
}
.barWrapper {
    height: 8px;
    overflow: hidden;
}
.progress {
    transition: width 1s ease-in-out;
    animation-name: slideInLeft;
    animation-duration: 5s; /* Changed Sept 14, 23 */
    animation-timing-function: ease-in-out;
    animation-delay: 0s;
}
@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.Imageeffects :hover{
  -ms-transform: scale(1.05); 
  -webkit-transform: scale(1.05);
   transform: scale(1.05);

}

/* image transition */

/* .productsPage .step {
  position: relative;
  border-radius: 10px;
  background: #e8e8e8 !important;
}

.productsPage .step:hover {

  border-radius: 10px;
  background: #ffefd4 !important;
  -ms-transform: scale(1.05); 
  -webkit-transform: scale(1.05); 
  transform: scale(1.05); 

} */

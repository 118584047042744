/* fonts */
/* Poppins  */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* Nunito  */
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');
/* DM Sans  */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

:root {
  --red: #cd1a1a;
  --redDark: #b30000;
  --secondColor: #ffa200;
  --lightColor: #ffefd4;
  --gradient: linear-gradient(90deg, var(--red), var(--secondColor));
  --textShadow: 0 1px 6px rgba(0,0,0,0.59);
  --bs-secondary-rgb: #555555;
  --lighterText: #e8e8e8;
  --textDark: #1f2122;
  --bgDark: #1f2122;
}

body {
  font-family: 'Nunito', sans-serif;
}


/* Utils */
.gradientText {
  background: linear-gradient(90deg, var(--red), var(--secondColor));
  background-clip: text;
  -webkit-background-clip: text; /* for Safari */
  -webkit-text-fill-color: transparent;
  color: transparent; /* fallback for unsupported browsers */
}
.gradientTextBottom {
  background: linear-gradient(to top, var(--red), #ffa200);
  background-clip: text;
  -webkit-background-clip: text; /* for Safari */
  -webkit-text-fill-color: transparent;
}

.gradientBg {
  background-image: linear-gradient(90deg, var(--red), var(--secondColor));
}

.text-secondary {
  color: var(--bs-secondary-rgb) !important;
}

.lighterText {
  color: var(--lighterText);
}


.lighterBg {
  background: var(--lighterText);
}

.bg-secondary {
  background-color: #FAFAFA !important;
}
.bgDark {
  background-color: var(--bgDark);
}

.blackLink {
  text-decoration: none;
  color: #000;
}

label {
  font-weight: 600;
}

::placeholder {
  color: #292929;
}

.bgSecondary {
  background-color: var(--lightColor);
}

.transition {
  transition: 400ms;
}

.scrollHide {
  scrollbar-width: none;
}

input,textarea:focus {
  outline: none;
}

.square {
  color: #ffa200;
  width: 8px;
  transform: rotate(45deg);
}

.xCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.yCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.titleFont {
  font-size: 1.5rem;
  font-family: Poppins, 'DM Sans';
  font-weight: 600;
}

.bigHeading {
  font-size: 3.5rem;
}

.allCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.xStart {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

/* .truncateText {
  --max-lines: 2;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--max-lines);
  position: relative;
  clip-path: polygon(0% 100%, 100% 100%, 100% 50%, 80% 0%, 0% 0%, 0% 100%);
}
.truncateText::after {
  content: "More";
  overflow: visible;
  position: absolute;
  width: fit-content;
  height: fit-content;
  top: 50%;
  left: 0;
  padding: 1rem 1.4rem;
  z-index: 99;
} */

.scrollBarHidden {
  overflow: scroll !important;
  scrollbar-width: none !important;
}
.scrollBarHidden::-webkit-scrollbar {
  display: none !important;
}
/* components classes  */

/* leave comment  */
.leaveComment button {
  width: 200px !important;
  height: 40px !important;
  border-radius: 3px !important;
}

.leaveComment input:checked {
  border: 1px solid #fff;
  background-color: #f7f7f7;
  background-color: black;
}

/* icons effects  */
.icons .icon {
  width: 33px;
  height: 33px;
  border: 1px solid var(--secondColor);
  color: var(--red);
  text-decoration: none;
  transition: 300ms;
  padding: 6px;
}
.icons .icon:hover {
  background-image: linear-gradient(90deg, var(--red), var(--secondColor));
  color: #fff !important;
  border: none;
}

/* Review Component  */
.boxShadowWhite {
  box-shadow: 0 5px 20px rgba(0,0,0,0.1);
}
.content-element {
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
}

.content-element.loaded {
  opacity: 1;
}


/* Sept 9, 23 ================ */
.fw500 {
  font-weight: 500 !important;
}

.fw600 {
  font-weight: 600 !important;
}

.fw700 {
  font-weight: 700 !important;
}

.text-justify {
  text-align: justify !important;
}
/* Sept 9, 23 ================ */

/* Sept 11, 23 ====================== */
.productsBg {
  background-color: var(--lightColor);
}

.top-right-rounded {
  border-top-right-radius: 0.5rem;
}

.top-left-rounded {
  border-top-left-radius: 0.5rem;
}
/* Sept 11, 23 ====================== */
/* Sept 12, 23 ====================== */
.faqBg {
  background-color: #fff2e8;
}

.textHoverEffect {
  transition: all 400ms ease-in-out !important;
}
.textHoverEffect:hover {
  color: var(--secondColor) !important;
}

.textVectorHoverEffect:hover {
  transition: all 400ms ease-in-out;
  color: var(--secondColor) !important;
}

.textVectorHoverEffect:hover .vector {
  transition: all 400ms ease-in-out !important;
  color: var(--secondColor) !important;
  scale: 1.06;
}

.cursorPointer {
  cursor: pointer !important;
}

@keyframes moveInRight {
  0% {
    opacity: 30%;
    transform: translateX(100px)
  }
  80% {
    transform: translateX(-10px)
  }
  100% {
    opacity: 100%;
    transform: translate(0)
  }
}

@media (max-width: 480px) {
  .smallPopup {
    right: 0 !important;
  }
  /* Sept 14, 23 ====================== */
  .navbar-brand {
    width: 150px;
  }
  /* Sept 14, 23 ====================== */
  /* Sept 15, 23 ====================== */
  .bigHeading {
    font-size: 2.5rem;
  }
  
  /* Sept 15, 23 ====================== */
}
/* Sept 12, 23 ====================== */

/* Sept 13, 23 ====================== */
.scaleOnHover img {
  transition: all 300ms ease-in-out;
}

.scaleOnHover:hover img{
  transform: scale(1.04);
}
/* Sept 13, 23 ====================== */

/* Sept 15, 23 ====================== */

.objectFit {
  object-fit: cover !important;
}

.textHoverGradient {
  transition: all 300ms ease-in-out !important;
}

.textHoverGradient:hover {
  background: linear-gradient(90deg, var(--red), var(--secondColor));
  background-clip: text;
  -webkit-background-clip: text; /* for Safari */
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.blogIcon {
  color: var(--red) !important;
}

/* Sept 15, 23 ====================== */
/* Sept 22, 23 ====================== while final project */
.slideAnimation {
  transform: translate(-50%, 30%) scale(0.2) !important;
}

/* Remove the default arrow buttons of input number*/
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
}

/* Hide the spinners in the textarea */
textarea {
  appearance: none;
  -moz-appearance: textfield;
  -webkit-appearance: none;
}


/* Sept 22, 23 ====================== while final project */

/* Sept 28, 23  */
/* products component  */

.productsPage .product:hover {
  border-color: var(--lightColor) !important;
  background-color: #fff !important;
}

.productsPage .product:hover .knowMoreBtn .rightCaret {
  transform: translateX(3px);
}

/* Sept 28, 23  */

.popupMask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 200%;
  height: 200%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.popupCenter {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
}

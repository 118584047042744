.aboutServiceContainer .points li {
    list-style-type: none;
    font-size: 18px !important;
    font-weight: 600;
    position: relative;
    padding-left: 22px;
}

.aboutServiceContainer .points li::before {
    content: url(../../../public/img/check-icon.svg);
    position: absolute;
    left: 0;
    top: 0;
}

@media(max-width: 991px) {
    .aboutServiceContainer .serviceVector {
        width:100px;
        bottom: -50px
    }
}
.hovereffect :hover{
    -ms-transform: scale(1.05); /* IE 9 */
    -webkit-transform: scale(1.05); /* Safari 3-8 */
    transform: scale(1.05); 
}
.assuarance .videoWrapper {
    width: 1000px;
    height: 500px;
    overflow: hidden;
    transform: rotate(-25deg) translate(100px, -150px);
}
.assuarance .imgWrapper {
    /* background-image: url("img/1.jpeg"); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    transform: rotate(25deg) scale(1.5, 1.5);
}
.assuarance .videoWrapper::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: 300ms;
}
.videoWrapper:hover .imgWrapper {
    transform: rotate(23deg) scale(1.6, 1.6);
}
.videoWrapper:hover::after {
    background-color: #00000088;

}

.hovereffect :hover{
    -ms-transform: scale(1.05); /* IE 9 */
    -webkit-transform: scale(1.05); /* Safari 3-8 */
    transform: scale(1.05); 
}
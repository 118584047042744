/* Sept 14, 23 ====================== */

#cursor {
    width: 40px;
    height: 40px;
    background-color: #ffa200af; 
    border-radius: 50%;
    position: absolute;
    pointer-events: none; /* ensure the cursor doesn't interfere with links */
    transform: translate(-50%, -50%);
    /* transform: translate(-46%, -454%); */
    transition: transform 2s linear;
    z-index: 99999999999999999999;
    display: none; /* display none by defaul */
  }

  .inAnimation {
    animation: inAnimation 200ms ease;
  }

  .outAnimation {
    animation: outAnimation 300ms ease;
  }
  

  @keyframes inAnimation {
    from {
        scale: 0;
        opacity: 0;
    } to {
        scale: 1;
        opacity: 1;
    }
  }

  @keyframes outAnimation {
    from {
        scale: 0;
        opacity: 0;
    } to {
        scale: 1;
        opacity: 1;
    }
  }

  /* Sept 14, 23 ====================== */
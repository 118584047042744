@media (max-width: 1150px) {
    .workingHours .text-responsive {
        font-size: 1rem !important;
    }
}
/* @media (max-width: 960px) {
    .workingHours .text-responsive {
        font-size: 1.2rem !important;
    }
} */
@media (max-width: 520px) {
    .workingHours .text-responsive {
        font-size: 0.8rem !important;
    }
    .workingHours .line {
        width: 50px !important;
    }
    .workingHours .overFlowing {
        display: none;
    }
}
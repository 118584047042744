.testimonial .star {
    font-size: .9rem;
    color: var(--secondColor);
}
.testimonial .review {
    font-size: 0.9rem;
}
.testimonial .feedbackCard:hover {
    /* border: 1px solid var(--secondColor) !important; */
    background-color: #e8e8e8 !important;
}
.paddingsection{
  padding-top: 10px !important;
}
.testimonialsection{
   width: 90% !important;
   height: 90% !important ;
   border-radius: 10px !important;
   box-shadow: 4px 4px 4px 4px rgb(229, 187, 109);
}

/* zoom effect */

.zoom {
    padding: 50px;
    margin: 0 auto;
    border-radius: 10px !important;
  }
  
  .zoom:hover {
    border-radius: 10px !important;
    background: #ffefd4 !important;
    -ms-transform: scale(1.05); /* IE 9 */
    -webkit-transform: scale(1.05); /* Safari 3-8 */
    transform: scale(1.05); 
  }
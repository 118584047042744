.heroBasic {
    height: 300px;
}
.blackShade,.mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    object-fit: cover;
}
.blackShade img {
    z-index: -1;
}
.textSection, .textSection > * {
    z-index: 19;
}
.mask{
    
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9;
}
.heroBasic .icon {
    height: 50px;
}
@media (max-width: 648px) {
    .mainVideoPopup {
        width: 420px !important;
        height: 250px !important;
    }
}

@media (max-width: 480px) {
    /* video popup  */
    .mainVideoPopup {
        width: 250px !important;
        height: 155px !important;
    }
}
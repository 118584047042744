
.contact .socialImg {
    animation: jump 8s linear infinite alternate;
    filter: drop-shadow(0 0 10px rgba(0,0,0,0.1))
}
@keyframes jump {
    0% {
        transform: translateY(15px);
    }
    25% {
        transform: translateY(25px);
    }
    50% {
        transform: translateX(15px);
    }
    75% {
        transform: translateX(25px);
    }
    100% {
        transform:translateY(15px);
    }
}

.imagesize{
  
    width: 100% !important;
}
.center{

    text-align: center !important;
    justify-content: center !important;
}



  
.footer {
    background-color: #191919;
}
.footer .company {
    border: 0.5px solid rgba(255,255,255,0.1);
}
.footer .company {
    width: 350px;
    height: 350px;
}
.footer .line {
    width: 90px;
    height: 2px;
    background: var(--lighterText);
}

.footer .icons .icon:hover {
    border: none;
}

.footer .ftBtn:hover {
    border-color: var(--secondColor) !important;
}

.footer .links a {
    text-decoration: none;
    color: #ffffff;
}

@media (max-width: 480px) {
    .footer .company {
        width: 80vw !important;
        height: 80vw !important;
    }

    .footer .line {
        width: 50px !important;
    }
}
.fontSize{
    font-size: 10 !important;
}
.shimmerCard {
    position: relative;
    -webkit-box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
    background-color: #ededed;
    border-radius: 6px;
    max-height: 500px;
    overflow: hidden;
    min-width: 350px;
    margin: 40px auto;


}
.shimmerBG {
    background: #ddd;
    background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
}
.shimmerCard .shimmer{
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
 
    background: linear-gradient(100deg,
    rgba(255,255,255,0) 20%,
    rgba(255,255,255,0.5) 50%,
    rgba(255,255,255,0) 80%);
 
    animation: shimmer 3s infinite ease-in-out;
}
 
@keyframes shimmer{
    from {
        transform: translateX(0%);
    }
    to{
        transform: translateX(100%);
    }
}


.media {
height: 200px;
}

.p-32 {
    padding: 32px;
}

.title-line {
    height: 24px;
    width: 100%;
    margin-bottom: 12px;
    border-radius: 20px;
}

.content-line {
    height: 8px;
    width: 100%;
    margin-bottom: 16px;
    border-radius: 8px;
}

.end {
    width: 40%;
    }


.m-t-24 {
    margin-top: 24px;
}
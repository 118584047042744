.posts .moreBtn:hover {
    color: var(--secondColor);
    transform: scale(1.03);
}
.posts .thumbnail:hover .postFeatureImage img {
    transform: scale(1.1);
}
.postFeatureImage {
    max-height: 200px;
}

.posts .thumbnail {
    padding: 2px;
    background-image: linear-gradient(90deg, var(--red), var(--secondColor));
}

/* Sept 15, 23 ============ */
.post .postTitle {
    text-decoration: none;
    color: #000;
}
.aboutUs .contentBox{
    position: absolute;
    width: 80%;
    bottom: -10%;
    left: -10%;
    background-color: var(--lightColor);
}
.aboutUs .contentBox::after {
    content: "";
    position: absolute;
    width: 1%;
    height: 100%;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to top, var(--red), var(--secondColor));
}
@media (max-width: 560px) {
    .aboutUs .contentBox h5 {
        font-size: 14px;
    }
    /* Sept 13, 23 ===== */
    .aboutUs .contentBox .quoteIcon {
        font-size: 18px !important;
    }
    .aboutUs .imageWitchBatch {
        margin: 2rem !important;
    }
    /* Sept 13, 23 ===== */
}

.imageHover :hover{
  
    -ms-transform: scale(1.05); /* IE 9 */
    -webkit-transform: scale(1.05); /* Safari 3-8 */
    transform: scale(1.05); 
  
}

  


.waveAnimation {
    background: url("https://admin.dizzibooster.com/public/images/zmagtujwper650bcd06d28cc.png") no-repeat;
    background-size: 200%;
    /* background-position-y: 26px;
    background-position-x: 0px; */
    animation: animate 3s linear infinite;
}
@keyframes animate {
    0% {
        background-position-y: 44px;
        background-position-x: 0px;
    }
    25% {
        background-position-y: 25px;
        background-position-x: -14px;
    }
    50% {
        
        background-position-y: 0px;
        background-position-x: -35px;
    }
    75% {
        background-position-y: -20px;
        background-position-x: -50px;
    }
    100% {
        background-position-y: -46px;
        background-position-x: -60px;
    }
}
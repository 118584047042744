.contactForm {
    background: #fff9ee;
    z-index: 99999;
}
.contactForm .field {
    width: 100%;
    padding: 10px 10px;
    background-color: var(--lightColor);
}
.contactForm input,textarea {
    padding: 0 10px;
    border: none;
    border-left: 1px solid var(--red);
}
.contactForm input,textarea:focus {
    outline: none;
}
.contactForm button {
    border-radius: 2px;
}
.contactForm .icon {
    color: var(--red);
}
.hero .leftNavbar {
    width: 8%;
    height: 100% !important;
}
.hero .heroSection {
    width: 92%;
}
.hero .lines span {
    width: 100%;
    height: 2px;
    transition: 300ms;
}
.hero .lines:hover span {
    width: 100% !important;
}

/* hero section  */
.hero {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: relative;
    /* background-image: url("https://demo-egenslab.b-cdn.net/html/techgen/preview/assets/images/banner/banner-five/banner5-2.jpg"); */
}
.blackShade .mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.blackShade img {
    z-index: -1;
   
}
.mask{
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9;
    
}

.hero  .row > *{
    position: relative;
    z-index: 10;
}
.hero .col-lg-6 h2 {
    font-size: 3.3rem;
    font-weight: 800;
}
.hero .text-secondary {
    color: rgb(189, 189, 189) !important;
}
.hero .laptop {
    position: absolute;
    bottom: -38%;
    filter: drop-shadow(0 5px 10px rgba(0,0,0,0.2));
}

.hero .recentPosts {
    z-index: 99;
    height: 100vh; /* Setp 13, 23 */
    width: 400px;
    top: 0;
    right: 0;
    overflow: scroll;
    scrollbar-width: none;
    transition: 600ms ease 0s !important;
    box-shadow: -5px 0 20px rgba(0,0,0,0.1);
}
.recentPosts::-webkit-scrollbar {
    display: none;
}
.hero .notVisible {
    transform: translateX(100%) !important;
}
.hero .visible {
    transform: translateX(0%) !important;
}

.hero .socialMedia .socialImg {
    width: 55px;
    height: 55px;
    max-width: 55px;
    max-height: 55px;
    text-decoration: none;
    color: #000;
    /* border-radius: 50%; */
}

.hero .socialImg .icon {
    position: absolute;
    width: 27px;
    height: 27px;
    top: -7%;
    right: 4%;
    border-radius: 50%;
    border: 1px solid var(--red);
    overflow: hidden;
}

@media (max-width: 992px) {
    .hero .laptop {
        position: relative;
    }
    .hero .leftNavbar {
        display: none;
    }
    .hero .heroSection {
        width: 100%;
    }
    .hero .laptopContainer {
        margin-top: 0 !important;
    }
}

.hovereffect :hover{

    -ms-transform: scale(1.05); /* IE 9 */
    -webkit-transform: scale(1.05); /* Safari 3-8 */
    transform: scale(1.05); 
}
